<template>
  <div class="container">
    <div class="box">
      <h2 class="title">Hulum Ticket Booking</h2>
    </div>
    <div class="barcode-container">
      <svg ref="barcode"></svg>
    </div>
    <div v-if="noValueDetected" class="message">No value detected.</div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
  data() {
    return {
      noValueDetected: false
    };
  },
  mounted() {
    const barcodeValue = this.$route.params.barcode;
    if (barcodeValue !== "") {
      try {
        this.generateBarcode(barcodeValue);
      } catch (error) {
        console.error('Barcode generation error:', error);
      }
    } else {
      this.noValueDetected = true;
    }
  },
  methods: {
    generateBarcode(value) {
      const barcodeElement = this.$refs.barcode;
      if (!barcodeElement || !barcodeElement.parentNode) return;

      barcodeElement.innerHTML = '';

      try {
        JsBarcode(barcodeElement, value);
      } catch (error) {
        console.error('JsBarcode error:', error);
      }
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.box {
  text-align: center;
  margin-bottom: 1px;
  border: 2px solid #078047;
}

.title {
  color: #109daf;
  animation: rainbow 5s linear infinite;
}

.barcode-container {
  text-align: center;
}

.message {
  margin-top: 10px;
}

@keyframes rainbow {
  0% { color: #d12a2ac9; }
  14% { color: #ff7f00; }
  28% { color: #021307a4; }
  42% { color: #5dbe1c; }
  57% { color: #04048ad3; }
  71% { color: #4b0082; }
  85% { color: #8f00ff; }
  100% { color: #09c9eb; }
}

@media (max-width: 200px) {
  .barcode-container {
    font-size: 16px;
  }
}

@media (max-width: 100px) {
  .barcode-container {
    font-size: 14px;
  }
}
</style>