import { createRouter,createWebHistory } from "vue-router";

import BarCode from  './pages/BarCode.vue'



const routes = [
 
 { path: '/barcode/:barcode',name:'BarCode',component:BarCode }
 ,

]

const router = createRouter({
    history:createWebHistory(),
    routes,
});

export default router;